import React, { useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import { get_resource, get_resource_details, resource_download } from '../api/ApiProduct';
import { useState } from 'react';
import { thumbnail } from '../api/Common';
import ResourceCard from '../components/ResourceCard';
import ModalPrice from '../components/ModalPrice';
const WorksheetView = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top of the page
    }, [pathname]);
    const breadcrumbStyle = {
        '--bs-breadcrumb-divider': '>'
    };
    const { id, name } = useParams();
    const [resourceDetails, setResourceDetails] = useState(null)
    const [youMayLikeResource, setYouMayLikeResource] = useState([])
    const [showModal, setShowModal] = useState(false);
    
    useEffect(() => {
        get_resource_details({id:id}).then((res)=>{
            setResourceDetails(res.data.data)
        }).catch((err)=>{

        })
    }, [pathname])

    useEffect(() => {
        let params = {limit: 4}
        get_resource(params).then((res)=>{
            setYouMayLikeResource(res.data.data.values);
        }).catch(()=>{

        })
    }, [])
    function download_resource(id){
        resource_download({id:id}).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;

            // Optional: Extract filename from headers, if available
            const contentDisposition = response.headers['content-disposition'];
            let fileName = resourceDetails.resource_title+'.zip';
            if (contentDisposition && contentDisposition.includes('filename=')) {
                fileName = contentDisposition.split('filename=')[1].split(';')[0];
            }
            
            link.setAttribute('download', fileName); // Set the downloaded filename
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link); // Clean up after download
        })
        .catch((error) => {
            if(error.response.status == 402){
                console.log(error.response)
                setShowModal(true);
            }
            else{
                document.getElementById('loginModalbtn').click();
                console.error('Error downloading file:', error);
            }
        });
    }
  return (
    <>
    {/* // <!-- Inner Banner Starts Here --> */}
{resourceDetails && 
<section class="inner-banner p-100">
    <div class="container">
        <div class="row top-row mb-0 align-items-end">
            <div class="col-md-12 position-relative">
                <div class="breadcrumbs">
                    <nav style={breadcrumbStyle} aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="#">Home</a></li>
                            <li class="breadcrumb-item active" aria-current="page">Worksheet</li>
                        </ol>
                    </nav>
                </div>
                <h2 class="site-title text-start">{resourceDetails.resource_title}</h2>
                {/* <p class="mb-0">Interactive, research-based Math and Science worksheets fostering<br /> independent learning with real-world applications and digital flexibility.</p> */}
                <p class="mb-0">{resourceDetails.resource_synopsis}</p>
            </div>
        </div>
    </div>
</section>
}
{resourceDetails && 
<section class="sheet-cnt p-100">
    <div class="container">
        <div class="row">
            <div class="col-md-4 left">
                <div class="card inwrp">
                    <div class="card-body">
                        <img src={thumbnail()+resourceDetails.thumbnail} alt="Image" class="w-100 img-fluid" />
                    </div>
                    <a  class="site-secondary-btn" onClick={()=>download_resource(resourceDetails.id)}><span><img src="/assets/images/icon-download.svg" alt="Icon" />Download</span></a>
                    {showModal && 
                    <ModalPrice setShowModal={setShowModal} showModal={showModal} resourceDetails={resourceDetails} className="modal-price" />
                    }
                    <p class="share-title">Share this</p>
                    <ul class="social-group d-flex gap-3">
                        <li><a href="javascript:void(0)"><img src="/assets/images/icon-insta.svg" alt="Icon" /></a></li>
                        <li><a href="javascript:void(0)"><img src="/assets/images/icon-fb.svg" alt="Icon" /></a></li>
                        <li><a href="javascript:void(0)"><img src="/assets/images/icon-twitter.svg" alt="Icon" /></a></li>
                        <li><a href="javascript:void(0)"><img src="/assets/images/icon-youtube.svg" alt="Icon" /></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-md-8 right">
                <div class="inwrp">
                    <div className="card">
                        <div className="card-body" dangerouslySetInnerHTML={{ __html: resourceDetails.resource_description }}>
                        </div>
                    </div>
                    {/* <div class="card">
                        <div class="card-body">
                            <p class="mb-0">Immerse yourself in the enchanting world of numbers with "Vacation with My Nani Maa," the delightful NCERT Class 3 Maths Chapter 4, designed to captivate young minds and introduce them to the foundational concepts of addition and subtraction. This unique chapter does more than just walk students through the basics; it takes them on a memorable journey to their Nani Maa's house, blending emotional narratives with critical thinking and mathematical skills. Through the cleverly structured Vacation with My Nani Maa Class 3 Maths Worksheet, children are invited to explore the realm of numbers using tens frames, number lines, ginladi, and bundles of sticks, transforming abstract concepts into tangible understanding.</p>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-body">
                            <p class="mb-0">The essence of this chapter lies not just in mastering numbers, but in fostering a deep, intuitive sense of mathematics in the minds of young learners. It encourages them to apply these concepts through various exciting methods, including estimation, mental addition, and mental subtraction, preparing them for a lifetime of problem-solving. Furthermore, the Vacation with My Nani Maa Class 3 Question Answer segment equips students with the confidence to tackle real-world problems, enhancing their analytical skills and comprehension.</p>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-body">
                            <p class="mb-0">As educators and parents search for resources that bring learning to life, the engaging worksheets and thoughtfully curated questions from "Vacation with My Nani Maa" offer an invaluable tool in this quest. With an unmatched blend of educational value and heartfelt storytelling, this chapter from WitKnowLearn stands as a beacon, illuminating the path for young mathematicians. Join us on this captivating journey into mathematics, where every lesson learned is an adventure, and every challenge overcome is a step towards a brighter, more understanding future.</p>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-body">
                            <p><strong>Class 3 Maths Chapter 4 Worksheet</strong></p>
                            <p class="mb-0">The Class 3 Maths Chapter 4 Worksheet is a meticulously designed educational tool aimed at reinforcing the mathematical concepts introduced in "Vacation with My Nani Maa". These worksheets serve as a practical extension of classroom learning, offering varied questions and puzzles that encapsulate addition and subtraction in engaging, real-world scenarios. Through interactive exercises, children are tasked with applying their newfound knowledge, utilizing techniques such as tens frames, number lines, and mental math. The worksheets are not just about finding the right answers; they're about cultivating a deeper understanding and appreciation for mathematics, making learning both enjoyable and effective.</p>
                        </div>
                    </div> */}
                    <a  class="site-secondary-btn ms-0" onClick={()=>download_resource(resourceDetails.id)}><span><img src="/assets/images/icon-download.svg" alt="Icon" />Download</span></a>
                    {showModal && 
                    <ModalPrice setShowModal={setShowModal} showModal={showModal} resourceDetails={resourceDetails}/>
                    }
                    <p class="share-title">Share this</p>
                    <ul class="social-group d-flex gap-3">
                        <li><a href="javascript:void(0)"><img src="/assets/images/icon-insta.svg" alt="Icon" /></a></li>
                        <li><a href="javascript:void(0)"><img src="/assets/images/icon-fb.svg" alt="Icon" /></a></li>
                        <li><a href="javascript:void(0)"><img src="/assets/images/icon-twitter.svg" alt="Icon" /></a></li>
                        <li><a href="javascript:void(0)"><img src="/assets/images/icon-youtube.svg" alt="Icon" /></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>}
{resourceDetails && 
<section class="worksheet-lists related-sheets p-100 pt-0">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h2 class="site-title">You may like these also</h2>
            </div>
            {youMayLikeResource?.map((ymlr, key)=>{return(
                <ResourceCard key={key} data={ymlr} />
            )})}
            {/* <div class="col-lg-3 col-md-6">
                <div class="card single-sheet">
                    <div class="card-head text-center">
                        <img src="/assets/images/ws-1.jpg" alt="Image" class="img-fluid" />
                    </div>
                    <div class="card-body p-0 pt-3">
                        <div class="title-wrp pt-1 d-flex row gx-2">
                            <div class="col">
                                <h4>Grade 1</h4>
                                <div class="ratings d-flex">
                                    <div class="img-groups d-flex gap-1">
                                        <img src="/assets/images/icon-star.svg" alt="Icon" />
                                        <img src="/assets/images/icon-star.svg" alt="Icon" />
                                        <img src="/assets/images/icon-star.svg" alt="Icon" />
                                        <img src="/assets/images/icon-star.svg" alt="Icon" />
                                        <img src="/assets/images/icon-star.svg" alt="Icon" />
                                    </div>
                                    <p class="mb-0 ms-2">(1 Reviews)</p>
                                </div>
                            </div>
                            <div class="col-auto text-end">
                                <img src="/assets/images/free.png" alt="Image" />
                            </div>
                        </div>
                        <h3>Vacation with My Nani Maa </h3>
                        <p>Vacation with My Nani Maa Class 3 Maths worksheet</p>
                        <div class="btn-wrp"><a href="javascript:void(0)" class="site-secondary-btn"><span>More Details</span></a></div>
                    </div>
                </div>
            </div> */}
            {/* <div class="col-lg-3 col-md-6">
                <div class="card single-sheet">
                    <div class="card-head text-center">
                        <img src="/assets/images/ws-2.jpg" alt="Image" class="img-fluid" />
                    </div>
                    <div class="card-body p-0 pt-3">
                        <div class="title-wrp pt-1 d-flex row gx-2">
                            <div class="col">
                                <h4>Grade 1</h4>
                                <div class="ratings d-flex">
                                    <div class="img-groups d-flex gap-1">
                                        <img src="/assets/images/icon-star.svg" alt="Icon" />
                                        <img src="/assets/images/icon-star.svg" alt="Icon" />
                                        <img src="/assets/images/icon-star.svg" alt="Icon" />
                                        <img src="/assets/images/icon-star.svg" alt="Icon" />
                                        <img src="/assets/images/icon-star.svg" alt="Icon" />
                                    </div>
                                    <p class="mb-0 ms-2">(1 Reviews)</p>
                                </div>
                            </div>
                        </div>
                        <h3>Vacation with My Nani Maa </h3>
                        <p>Vacation with My Nani Maa Class 3 Maths worksheet</p>
                        <div class="btn-wrp"><a href="javascript:void(0)" class="site-secondary-btn"><span>More Details</span></a></div>
                    </div>
                </div>
            </div> */}
            {/* <div class="col-lg-3 col-md-6">
                <div class="card single-sheet">
                    <div class="card-head text-center">
                        <img src="/assets/images/ws-3.jpg" alt="Image" class="img-fluid" />
                    </div>
                    <div class="card-body p-0 pt-3">
                        <div class="title-wrp pt-1 d-flex row gx-2">
                            <div class="col">
                                <h4>Grade 1</h4>
                                <div class="ratings d-flex">
                                    <div class="img-groups d-flex gap-1">
                                        <img src="/assets/images/icon-star.svg" alt="Icon" />
                                        <img src="/assets/images/icon-star.svg" alt="Icon" />
                                        <img src="/assets/images/icon-star.svg" alt="Icon" />
                                        <img src="/assets/images/icon-star.svg" alt="Icon" />
                                        <img src="/assets/images/icon-star.svg" alt="Icon" />
                                    </div>
                                    <p class="mb-0 ms-2">(1 Reviews)</p>
                                </div>
                            </div>
                        </div>
                        <h3>Vacation with My Nani Maa </h3>
                        <p>Vacation with My Nani Maa Class 3 Maths worksheet</p>
                        <div class="btn-wrp"><a href="javascript:void(0)" class="site-secondary-btn"><span>More Details</span></a></div>
                    </div>
                </div>
            </div> */}
            {/* <div class="col-lg-3 col-md-6">
                <div class="card single-sheet">
                    <div class="card-head text-center">
                        <img src="/assets/images/ws-4.jpg" alt="Image" class="img-fluid" />
                    </div>
                    <div class="card-body p-0 pt-3">
                        <div class="title-wrp pt-1 d-flex row gx-2">
                            <div class="col">
                                <h4>Grade 1</h4>
                            </div>
                        </div>
                        <h3>Vacation with My Nani Maa </h3>
                        <p>Vacation with My Nani Maa Class 3 Maths worksheet</p>
                        <div class="btn-wrp"><a href="javascript:void(0)" class="site-secondary-btn"><span>More Details</span></a></div>
                    </div>
                </div>
            </div> */}
        </div>
    </div>
</section>}
{/* <!-- Related Sheet Ends Here --> */}
</>
  )
}

export default WorksheetView