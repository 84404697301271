import axios from './AxiosDefault'; 
import { configuration } from './Common';

export function register(data) {
    return axios.post('/register', data);
}
export function login(data) {
    return axios.post('/login', data);
}
export function get_profile() {
    let config = configuration();
    return axios.get('/get/profile', config);
}
export function get_menu_category(){
    let config = configuration();
    return axios.get('/get/menu/category', config);
}
export function logout(){
    let config = configuration();
    return axios.get('/logout', config);
}
export function change_password(data){
    let config = configuration();
    return axios.post('/change/password', data, config);
}
export function forgot_password(data){
    let config = configuration();
    return axios.post('/send/otp', data, config);
}
export function verify_otp(data){
    let config = configuration();
    return axios.post('/verify/otp', data, config);
}
export function reset_password(data){
    let config = configuration();
    return axios.post('/reset/password', data, config);
}