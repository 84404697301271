import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Footer from './components/Footer';
import Header from './components/Header';
import Home from './pages/Home';
import Membership from './pages/Membership';
import Worksheet from './pages/Worksheet';
import View from './pages/View';
import Dashboard from './pages/Dashboard';
import { get_profile } from './api/ApiAuth';

function App() {
  const [profile, setProfile] = useState(null);
  useEffect(()=>{
    get_profile().then((res)=>{
      console.log(res.data)
        setProfile(res.data.data)
    }).catch((err)=>{

    })
  },[])
  return (
    <Router>
      <Header profile={profile} setProfile={setProfile} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/membership" element={<Membership setProfile={setProfile} profile={profile}/>} />
        <Route path="/worksheet" element={<Worksheet />} />
        <Route path="/notes" element={<Worksheet />} />
        <Route path="/view/:id/:name" element={<View />} />
        <Route path="/dashboard" element={<Dashboard profile={profile} setProfile={setProfile} />} />

      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
;