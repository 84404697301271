import React from 'react';
import DownloadCard from './DownloadCard';
import Slider from 'react-slick';

const DownloadScrollCard = ({bannerSliderDatas, lulu}) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    autoplay: false,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: false,
    nextArrow: false,
    customPaging: (i) => (
      <div className="slick-dot"></div>
    ),
  };

  return (
    <div className="download-scroll-card-container">
      <Slider {...settings} className="ms-auto slider-container">
        {bannerSliderDatas?.map((data, index) => (
          <div key={index} className="slider-item">
            <DownloadCard data={data}/>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default DownloadScrollCard;
