import React, { useState } from 'react'
import { login } from '../api/ApiAuth';
import { GoogleLogin } from '@react-oauth/google';
import axios from 'axios';
const ModalLogin = ({setProfile}) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [alertError, setAlertError] = useState(null)
    const handleToggle = () => {
      setIsPasswordVisible(!isPasswordVisible);
    };  

    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({
        password: '',
        email: '',
    });
    function form_data(e){
        setFormData(prev=>({...prev, [e.target.name]:e.target.value}))
    }
    function submit_login(){
        const validationErrors = validateForm();
        console.log(Object.keys(validationErrors));
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        setErrors({})
        login(formData).then((res)=>{
            setProfile(res.data.user);
            var closeButton = document.querySelector('.closeModalButton');
            closeButton.click();
        }).catch((err)=>{
            if (err.response && err.response.status === 422) {
                const apiErrors = err.response.data.errors;
                setErrors(apiErrors);  // Set server-side validation errors
            }
            else{
                setAlertError('something went wrong');
            }
        })
    }
    function validateForm() {
        {setAlertError(null)}
        const newErrors = {};
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!formData.email) {
            newErrors.email = 'Email is required';
        } else if (!emailPattern.test(formData.email)) {
            newErrors.email = 'Please enter a valid email';
        }
        if (!formData.password) {
            newErrors.password = 'Password is required';
        } 
        return newErrors;
    }

    const handleLoginSuccess = async (response) => {
        const { credential } = response;
        try {
          const { data } = await axios.post('google-login', {
            token: credential,
          });
    
          // You can now handle the user data and token returned from your backend
        setProfile(data.user);
            var closeButton = document.querySelector('.closeModalButton');
            closeButton.click();
            console.log('User data from backend:', data);
        } catch (error) {
            console.error('Google login error:', error);
        }
      };
    
      const handleLoginFailure = () => {
        console.log('Login Failed');
      };
  return (
    <div class="modal fade site-modal login-modal" id="loginModal" tabindex="-1" aria-labelledby="loginModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content rounded-4">
                    <div class="modal-body d-flex flex-column align-items-center px-4 py-4 mx-auto">
                        <div class="row w-100 gx-5">
                            <div class="col-lg-12 align-self-center right p-0">
                                <img src="/assets/images/logo.svg" alt="Logo" class="img-fluid mx-auto d-block" />
                                <h2 class="site-title"><span>Login</span></h2>
                                <button type="button" class="btn-close closeModalButton d-none " data-bs-dismiss="modal" aria-label="Close" ></button>
                                {alertError && (
                                <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                    {alertError}
                                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                </div>
                                )} 
                                {/* <form action="" method="post"> */}
                                    <div class="form-group mb-4">
                                        <input type="email" class="form-control" id="loginMail" name="email" placeholder="E-mail" required onChange={(e)=>form_data(e)}/>
                                        {errors.email && <span className="text-danger">{errors.email}</span>}

                                    </div>
                                    <div class="form-group mb-4 position-relative">
                                        <input type={isPasswordVisible ? "text" : "password"} class="form-control" id="loginPassword" name="password" placeholder="Password" required onChange={(e)=>form_data(e)}/>
                                        <a href="javascript:void(0)" class="psd-toggle"  onClick={handleToggle}><i class={`fa-regular fa ${isPasswordVisible ? 'fa-eye' : 'fa-eye-slash'}`}></i></a>               
                                        {errors.password && <span className="text-danger">{errors.password}</span>}
                                        <p class="forget-psd text-end"><a href="javascript:void(0)" class="text-decoration-none" data-bs-target="#forgotPasswordModal" data-bs-dismiss="modal" data-bs-toggle="modal">Forget Password?</a></p>
                                    </div>
                                    <button type="submit" class="btn site-btn mx-auto d-block mt-4" onClick={submit_login}>Sign In</button>
                                    <div class="goggle-signin text-center">
                                        <p class="mb-1 w-10">or continue with 
                                        <GoogleLogin
                                            onSuccess={handleLoginSuccess}
                                            onError={handleLoginFailure}
                                        />
                                            {/* <a href="#" class="google-btn d-table">
                                                <img src="/assets/images/icon-google.svg" alt="Icon" />
                                            </a> */}
                                        </p>
                                    </div>
                                    <div class="new-signup text-center">
                                        <p class="mb-1">Don't have an account? <a href="javascript:void(0)" class="reg-btn fw-bold" data-bs-target="#registerModal" data-bs-dismiss="modal" data-bs-toggle="modal">Register here</a></p>
                                    </div>
                                {/* </form> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default ModalLogin