import React from 'react'

const TesimonalCard = () => {
  return (
    <div class="single-item">
        <div class="inwrp">
            <div class="img-group">
                <img src="/assets/images/author-2.png" alt="Image" />
            </div>
            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed.</p>
            <div class="author-wrp">
                <p><strong>Samual Karl</strong></p>
                <p>Parent</p>
            </div>
        </div>
    </div>
  )
}

export default TesimonalCard