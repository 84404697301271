// import React, { useState, useEffect } from 'react';
// import { forgot_password, reset_password, verify_otp } from '../api/ApiAuth';

// const ModalForgotPassword = () => {
//     const [currentScreen, setCurrentScreen] = useState('email'); // 'otp' or 'password'
//     const [errors, setErrors] = useState({});
//     const [alertError, setAlertError] = useState(null);
//     const [formData, setFormData] = useState({ email: '', otp: '', password: '', confirmPassword: '' });
//     const [timer, setTimer] = useState(0);
  
//     useEffect(() => {
//         if (timer > 0) {
//         const interval = setInterval(() => {
//             setTimer((prev) => prev - 1);
//         }, 1000);
//         return () => clearInterval(interval);
//         }
//     }, [timer]);

//     const formDataHandler = (e) => {
//         setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }));
//     };

//     const handleSendOtp = () => {
//         forgot_password(formData).then((res)=>{
//             setCurrentScreen('otp');
//             setTimer(10);
//         }).catch((err)=>{

//         })
        
//     };

//     const handleVerifyOtp = () => {
//         verify_otp(formData).then((res)=>{
//             setCurrentScreen('password');        
//         }).catch((err)=>{

//         })
//     };

//     const handleResetPassword = () => {
//         reset_password(formData).then((res)=>{
//             document.getElementById('loginModalbtn').click();
//         }).catch((err)=>{

//         })
//     };

//   return (
//     <div className="modal fade site-modal register-modal" id="forgotPasswordModal" tabIndex="-1" aria-labelledby="forgotPasswordModalLabel" aria-hidden="true">
//       <div className="modal-dialog modal-dialog-centered modal-lg">
//         <div className="modal-content rounded-4">
//           <div className="modal-body d-flex flex-column align-items-center px-4 py-4 mx-auto">
//             <div className="row w-100 gx-5">
//               <div className="col-lg-12 align-self-center right p-0">
//                 <h2 className="site-title"><span>Forgot Password</span></h2>
//                 {alertError && (
//                   <div className="alert alert-danger alert-dismissible fade show" role="alert">
//                     {alertError}
//                     <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
//                   </div>
//                 )}
                
//                 {currentScreen === 'email' && (
//                   <div>
//                     <div className="form-group mb-4">
//                       <input
//                         type="email"
//                         className="form-control"
//                         id="regMail"
//                         name="email"
//                         placeholder="Please Enter Your Register Email"
//                         required
//                         onChange={formDataHandler}
//                       />
//                       {errors.email && <span className="text-danger">{errors.email}</span>}
//                     </div>
//                     <button
//                       type="button"
//                       className="btn site-btn mx-auto d-block mt-4"
//                       onClick={handleSendOtp}
//                     >
//                       Send OTP
//                     </button>
//                   </div>
//                 )}

//                 {currentScreen === 'otp' && (
//                   <div>
//                     <div className="form-group mb-4">
//                       <input
//                         type="text"
//                         className="form-control"
//                         id="otp"
//                         name="otp"
//                         placeholder="Enter OTP"
//                         required
//                         onChange={formDataHandler}
//                       />
//                       {errors.otp && <span className="text-danger">{errors.otp}</span>}
//                     </div>
//                     <button
//                       type="button"
//                       className="btn site-btn mx-auto d-block mt-4"
//                       onClick={handleVerifyOtp}
//                     >
//                       Verify OTP
//                     </button>
//                     {timer > 0 && (
//                       <div className="mt-2">
//                         <p>Resend OTP in: {Math.floor(timer / 60)}:{String(timer % 60).padStart(2, '0')}</p>
//                       </div>
//                     )}
//                     {timer === 0 && (
//                       <button
//                         type="button"
//                         className="btn site-btn mx-auto d-block mt-4"
//                         onClick={() => setTimer(10)} // Resend OTP button
//                       >
//                         Resend OTP
//                       </button>
//                     )}
//                   </div>
//                 )}

//                 {currentScreen === 'password' && (
//                   <div>
//                     <div className="form-group mb-4">
//                       <input
//                         type="password"
//                         className="form-control"
//                         id="password"
//                         name="password"
//                         placeholder="New Password"
//                         required
//                         onChange={formDataHandler}
//                       />
//                       {errors.password && <span className="text-danger">{errors.password}</span>}
//                     </div>
//                     <div className="form-group mb-4">
//                       <input
//                         type="password"
//                         className="form-control"
//                         id="confirmPassword"
//                         name="confirmPassword"
//                         placeholder="Confirm Password"
//                         required
//                         onChange={formDataHandler}
//                       />
//                       {errors.confirmPassword && <span className="text-danger">{errors.confirmPassword}</span>}
//                     </div>
//                     <button
//                       type="button"
//                       className="btn site-btn mx-auto d-block mt-4"
//                       onClick={handleResetPassword}
//                     >
//                       Reset Password
//                     </button>
//                   </div>
//                 )}
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ModalForgotPassword;
import React, { useState, useEffect } from 'react';
import { forgot_password, reset_password, verify_otp } from '../api/ApiAuth';

const ModalForgotPassword = () => {
    const [currentScreen, setCurrentScreen] = useState('email'); // 'otp' or 'password'
    const [errors, setErrors] = useState({});
    const [alertMessage, setAlertMessage] = useState(null);
    const [formData, setFormData] = useState({ email: '', otp: '', password: '', password_confirmation: '' });
    const [timer, setTimer] = useState(0);

    useEffect(() => {
        if (timer > 0) {
            const interval = setInterval(() => {
                setTimer((prev) => prev - 1);
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [timer]);

    const formDataHandler = (e) => {
        setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }));
    };

    const handleSendOtp = () => {
        forgot_password(formData)
            .then((res) => {
                setCurrentScreen('otp');
                setTimer(120); // Set timer to 2 minutes (120 seconds)
                setAlertMessage({ type: 'success', message: 'OTP sent successfully' });
            })
            .catch((err) => {
                setErrors(err.errors || {});
                setAlertMessage({ type: 'danger', message: err.response.data.message || 'An error occurred' });
            });
    };

    const handleVerifyOtp = () => {
        verify_otp(formData)
            .then((res) => {
                setCurrentScreen('password');
                setAlertMessage({ type: 'success', message: 'OTP verified successfully' });
            })
            .catch((err) => {
                setErrors(err.errors || {});
                setAlertMessage({ type: 'danger', message: err.response.data.message || 'Invalid OTP' });
                
            });
    };

    const handleResetPassword = () => {
        reset_password(formData)
            .then((res) => {
                document.getElementById('loginModalbtn').click();
                setAlertMessage({ type: 'success', message: 'Password reset successfully' });
            })
            .catch((err) => {
                setErrors(err.errors || {});
                setAlertMessage({ type: 'danger', message: err.response.data.message || 'An error occurred' });
            });
    };

    return (
        <div className="modal fade site-modal register-modal" id="forgotPasswordModal" tabIndex="-1" aria-labelledby="forgotPasswordModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content rounded-4">
                    <div className="modal-body d-flex flex-column align-items-center px-4 py-4 mx-auto">
                        <div className="row w-100 gx-5">
                            <div className="col-lg-12 align-self-center right p-0">
                                <h2 className="site-title"><span>Forgot Password</span></h2>
                                {alertMessage && (
                                    <div className={`alert alert-${alertMessage.type} alert-dismissible fade show`} role="alert">
                                        {alertMessage.message}
                                        {/* <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button> */}
                                    </div>
                                )}
                                
                                {currentScreen === 'email' && (
                                    <div>
                                        <div className="form-group mb-4">
                                            <input
                                                type="email"
                                                className="form-control"
                                                id="regMail"
                                                name="email"
                                                placeholder="Please Enter Your Register Email"
                                                required
                                                onChange={formDataHandler}
                                            />
                                            {errors.email && <span className="text-danger">{errors.email}</span>}
                                        </div>
                                        <button
                                            type="button"
                                            className="btn site-btn mx-auto d-block mt-4"
                                            onClick={handleSendOtp}
                                        >
                                            Send OTP
                                        </button>
                                    </div>
                                )}

                                {currentScreen === 'otp' && (
                                    <div>
                                        <div className="form-group mb-4">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="otp"
                                                name="otp"
                                                placeholder="Enter OTP"
                                                required
                                                onChange={formDataHandler}
                                            />
                                            {errors.otp && <span className="text-danger">{errors.otp}</span>}
                                        </div>
                                        <button
                                            type="button"
                                            className="btn site-btn mx-auto d-block mt-4"
                                            onClick={handleVerifyOtp}
                                        >
                                            Verify OTP
                                        </button>
                                        {timer > 0 && (
                                            <div className="mt-2">
                                                <p>Resend OTP in: {Math.floor(timer / 60)}:{String(timer % 60).padStart(2, '0')}</p>
                                            </div>
                                        )}
                                        {timer === 0 && (
                                            <button
                                                type="button"
                                                className="btn site-btn mx-auto d-block mt-4"
                                                onClick={handleSendOtp} // Resend OTP button
                                            >
                                                Resend OTP
                                            </button>
                                        )}
                                    </div>
                                )}

                                {currentScreen === 'password' && (
                                    <div>
                                        <div className="form-group mb-4">
                                            <input
                                                type="password"
                                                className="form-control"
                                                id="password"
                                                name="password"
                                                placeholder="New Password"
                                                required
                                                onChange={formDataHandler}
                                            />
                                            {errors.password && <span className="text-danger">{errors.password}</span>}
                                        </div>
                                        <div className="form-group mb-4">
                                            <input
                                                type="password"
                                                className="form-control"
                                                id="password_confirmation"
                                                name="password_confirmation"
                                                placeholder="Confirm Password"
                                                required
                                                onChange={formDataHandler}
                                            />
                                            {errors.password_confirmation && <span className="text-danger">{errors.password_confirmation}</span>}
                                        </div>
                                        <button
                                            type="button"
                                            className="btn site-btn mx-auto d-block mt-4"
                                            onClick={handleResetPassword}
                                        >
                                            Reset Password
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalForgotPassword;
