import React, { useEffect, useState } from 'react'
import DownloadScrollCard from '../components/DownloadSlideCard';
import TesimonalCard from '../components/TesimonalCard';
import DownloadCard from '../components/DownloadCard';
import { get_board_list, get_resource } from '../api/ApiProduct';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import { useLocation } from 'react-router-dom';
const Home = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top of the page
    }, [pathname]);
    const [bannerSliderDatas, setBannerSliderDatas] = useState([]);
    const [boardList, setBoardList] = useState([]);
    const [boardWiseResource, setBoardWiseResource] = useState([]);
    const [clickBoardId, setClickBoardId] = useState(null);
    const [explodeResource, setExplodeResource] = useState([]);

    const [resourceType, setResourceType] = useState(1);

    useEffect(() => {
        const menuItems = document.querySelectorAll(".main-menu > li > a");
        menuItems.forEach(item => item.classList.remove("active"));
        const homeMenuItem = document.querySelector(".main-menu > li > a.home-menu");
        if (homeMenuItem) {
          homeMenuItem.classList.add("active");
        }
    }, []);
    useEffect(() => {
        let params = {limit: 3, resource_type: resourceType}
        get_resource(params).then((res)=>{
            setBannerSliderDatas(res.data.data.values);
        }).catch(()=>{

        })
    }, [resourceType])
    useEffect(() => {
        get_board_list().then((res)=>{
            setBoardList(res.data.data);
            // setBoardList()
        }).catch(()=>{

        })
    }, [])
    useEffect(() => {
        let params = {limit: 2, board_id: clickBoardId || boardList[0], resource_type: resourceType}
        get_resource(params).then((res)=>{
            setBoardWiseResource(res.data.data.values);
        }).catch(()=>{

        })
    }, [clickBoardId, resourceType])
    useEffect(() => {
        let params = {limit: 9, resource_type: resourceType}
        get_resource(params).then((res)=>{
            const resources = res.data.data.values;
            const chunks = chunkArray(resources, 3);
            setExplodeResource(chunks);
        }).catch(()=>{

        })
    }, [resourceType])
    function get_board_wise_resource(board_id){
        setBoardWiseResource([]);
        setClickBoardId(board_id)
    }

    const chunkArray = (array, chunkSize) => {
        const chunks = [];
        for (let i = 0; i < array.length; i += chunkSize) {
            chunks.push(array.slice(i, i + chunkSize));
        }
        return chunks;
    };
  return (
    <>
        <section class="banner">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-md-6 left align-self-center text-center text-md-start">
                        <h1 class="site-title text-center text-md-start">Build the skills <br />To Drive Your Career</h1>
                        <p>Knowledge is power, but the right<br /> knowledge can empower.</p>
                    </div>
                    <div class="col-lg-4 col-md-4 middle d-none d-lg-block">
                        <img src="/assets/images/banner-img.png" alt="Image" class="d-block mx-auto img-fluid" />
                    </div>
                    {bannerSliderDatas.length > 0 &&
                    <div class="col-lg-4 col-md-6 right">
                        <DownloadScrollCard bannerSliderDatas={bannerSliderDatas}/>
                    </div>
                    }
                </div>
            </div>
        </section>
        {/* <!-- High Level Notes Ends Here --> */}

        {/* <!-- Resource Category Starts Here --> */}
        <section class="resource-category p-100 pt-0">
            <div class="container">
                <div class="row title-wrp text-center">
                    <div class="col-md-12">
                        <h2 class="site-title">Resource <span>Categorization</span></h2>
                        <p>Explore Top level Categories That Change Your Entire Life</p>
                        <ul class="d-flex justify-content-center">
                            <li><a href="javascript:void(0)" onClick={()=>setResourceType(1)}>Work Sheet</a></li>
                            <li><a href="javascript:void(0)">Mock Test Series</a></li>
                            <li><a href="javascript:void(0)" onClick={()=>setResourceType(2)}>Notes</a></li>
                        </ul>
                    </div>
                </div>
                <div class="row justify-content-center cnt-wrp">
                    <div class="col-lg-3 col-md-4">
                        <div class="inwrp">
                            <img src="/assets/images/parents-img.jpg" alt="Image" />
                            <h3>Parents</h3>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="inwrp">
                            <img src="/assets/images/students-img.jpg" alt="Image" />
                            <h3>Teachers</h3>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="inwrp">
                            <img src="/assets/images/teachers-img.jpg" alt="Image" />
                            <h3>Students</h3>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- Resource Category Ends Here --> */}

        {/* <!-- High Level Notes Starts Here --> */}
        <section class="highlevel-notes p-100">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 text-center position-relative">
                        <h2>High-Level Notes<br /> and Worksheet</h2>
                        <p>With digital media over powering our lives, students, teachers, parents, professionals,our overloaded<br /> with information .The right source of information saves us a lot of time. Witknowlearn thus aims to <br />reach out of every students, teachers, parents, professionals in the field of education.</p>
                        <h3>15k</h3>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- High Level Notes Ends Here --> */}

        {/* <!-- Explore Notes Starts Here --> */}
        <section class="explore-notes p-100">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="d-flex align-items-start tab-parent">
                            <div class="nav flex-column nav-pills me-xxl-5" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                <h4 class="sub-title">All in One for Education</h4>
                                <h2 class="site-title text-capitalize text-start">Choose your level and <br />explore <span>all Notes</span></h2>
                                
                                {boardList?.map((bl, key)=>{ console.log(bl); return(
                                <button class={`nav-link ${bl.id === (clickBoardId || boardList[0].id) ? "active" : ""}`} id="cbse-tab-button" data-bs-toggle="pill" data-bs-target="#cbse-tab" type="button" role="tab" aria-controls="cbse-tab" aria-selected="true" onClick={()=>get_board_wise_resource(bl.id)}><img src="/assets/images/icon-cbse.svg" alt="Icon" key={key} /> {bl.board_display_name.toUpperCase()}</button>
                                )})}
                                {/* <button class="nav-link" id="primary-tab-button" data-bs-toggle="pill" data-bs-target="#primary-tab" type="button" role="tab" aria-controls="primary-tab" aria-selected="false"><img src="/assets/images/icon-primary.svg" alt="Icon" /> Pre Primary</button>
                                
                                <button class="nav-link" id="nursery-tab-button" data-bs-toggle="pill" data-bs-target="#nursery-tab" type="button" role="tab" aria-controls="nursery-tab" aria-selected="false"><img src="/assets/images/icon-nursery.svg" alt="Icon" /> Nursery</button>
                                <button class="nav-link" id="test-tab-button" data-bs-toggle="pill" data-bs-target="#test-tab" type="button" role="tab" aria-controls="test-tab" aria-selected="false"><img src="/assets/images/icon-mock-test.svg" alt="Icon" /> Mock Test Series</button> */}
                            </div>
                            <div class="tab-content flex-fill ms-5" id="v-pills-tabContent">
                                <div class="" tabindex="0">
                                    <div class="row">
                                        {boardWiseResource?.map((bwr, key)=>{return(
                                        <div class="col-md-6" key={key}>
                                            <DownloadCard data={bwr}/>
                                        </div>)})}
                                        {/* <div class="col-md-6">
                                            <DownloadCard />
                                        </div> */}
                                    </div>
                                </div>
                                {/* <div class="tab-pane fade" id="primary-tab" role="tabpanel" aria-labelledby="primary-tab-button" tabindex="0">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="feature-box bg-white">
                                                <div class="imgwrp">
                                                    <img src="/assets/images/feature-1.png" alt="Image" />
                                                    <p>World-Class<br /> Stunning Worksheet</p>
                                                </div>
                                                <div class="cntwrp">
                                                    <p>NCERT Class 3 Maths Chapter<br /> 3 Double Century Worksheet</p>
                                                    <a href="javascript:void(0)" class="site-secondary-btn"><span>Download</span></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="feature-box bg-white">
                                                <div class="imgwrp">
                                                    <img src="/assets/images/feature-2.png" alt="Image" />
                                                    <p>World-Class<br /> Stunning Worksheet</p>
                                                </div>
                                                <div class="cntwrp">
                                                    <p>NCERT Class 3 Maths Chapter<br /> 3 Double Century Worksheet</p>
                                                    <a href="javascript:void(0)" class="site-secondary-btn"><span>Download</span></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                {/* <div class="tab-pane fade" id="nursery-tab" role="tabpanel" aria-labelledby="nursery-tab-button" tabindex="0">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="feature-box bg-white">
                                                <div class="imgwrp">
                                                    <img src="/assets/images/feature-1.png" alt="Image" />
                                                    <p>World-Class<br /> Stunning Worksheet</p>
                                                </div>
                                                <div class="cntwrp">
                                                    <p>NCERT Class 3 Maths Chapter<br /> 3 Double Century Worksheet</p>
                                                    <a href="javascript:void(0)" class="site-secondary-btn"><span>Download</span></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="feature-box bg-white">
                                                <div class="imgwrp">
                                                    <img src="/assets/images/feature-2.png" alt="Image" />
                                                    <p>World-Class<br /> Stunning Worksheet</p>
                                                </div>
                                                <div class="cntwrp">
                                                    <p>NCERT Class 3 Maths Chapter<br /> 3 Double Century Worksheet</p>
                                                    <a href="javascript:void(0)" class="site-secondary-btn"><span>Download</span></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                {/* <div class="tab-pane fade" id="test-tab" role="tabpanel" aria-labelledby="test-tab-button" tabindex="0">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="feature-box bg-white">
                                                <div class="imgwrp">
                                                    <img src="/assets/images/feature-1.png" alt="Image" />
                                                    <p>World-Class<br /> Stunning Worksheet</p>
                                                </div>
                                                <div class="cntwrp">
                                                    <p>NCERT Class 3 Maths Chapter<br /> 3 Double Century Worksheet</p>
                                                    <a href="javascript:void(0)" class="site-secondary-btn"><span>Download</span></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="feature-box bg-white">
                                                <div class="imgwrp">
                                                    <img src="/assets/images/feature-2.png" alt="Image" />
                                                    <p>World-Class<br /> Stunning Worksheet</p>
                                                </div>
                                                <div class="cntwrp">
                                                    <p>NCERT Class 3 Maths Chapter<br /> 3 Double Century Worksheet</p>
                                                    <a href="javascript:void(0)" class="site-secondary-btn"><span>Download</span></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- Explore Notes Ends Here --> */}

        {/* <!-- Explore Worksheet Starts Here --> */}
        <section class="explore-worksheet p-100 pt-0">
            <div class="container">
                <div class="row title-wrp">
                    <div class="col-md-12 text-center position-relative">
                        <h2 class="site-title"><span>Explore Worksheet</span></h2>
                        <p>Explore Top level Categories That Change Your Entire Life</p>
                    </div>
                </div>
                <div class="row justify-content-center cnt-wrp">
                    {explodeResource?.map((er, key)=>{return(
                    <div class="col-lg-4 col-md-6 right">
                        <DownloadScrollCard bannerSliderDatas={bannerSliderDatas}/>
                    </div>
                    )})} 
                    {/* <div class="col-xxl-auto col-auto" >
                        <DownloadScrollCard bannerSliderDatas={bannerSliderDatas}/>
                    </div> */}
                    
                    {/* <div class="col-xxl-auto col-auto">
                        <DownloadScrollCard />
                    </div>
                    <div class="col-xxl-auto col-auto">
                        <DownloadScrollCard />
                    </div> */}
                </div>
            </div>
        </section>
        {/* <!-- Explore Worksheet Ends Here --> */}

        {/* <!-- Testimonials Starts Here --> */}
        <section class="testimonials p-100 pt-0">
            <div class="container">
                <div class="row title-wrp">
                    <div class="col-md-12 text-center position-relative">
                        <h2 class="site-title"><span>Our Client Says</span></h2>
                    </div>
                </div>
                <div class="row cnt-wrp">
                    <div class="col-md-12">
                        {/* <div class="testimonial-carousel owl-carousel owl-theme ms-auto">
                            <TesimonalCard />
                            <TesimonalCard />
                            <TesimonalCard />
                            <TesimonalCard />
                            <TesimonalCard />
                        </div> */}
                        <div className='testimonial-carousel'>

                        <Swiper
                                spaceBetween={30}
                                slidesPerView={2}
                                loop={true}
                                autoplay={{
                                    delay: 1000,
                                    disableOnInteraction: false,
                                }}
                                pagination={{ clickable: true }}
                                navigation={false}
                                breakpoints={{
                                    640: {
                                        slidesPerView: 1,
                                    },
                                    768: {
                                        slidesPerView: 2,
                                    },
                                }}
                            >
                                {/* Swiper Slides */}
                                <SwiperSlide>
                                    <TesimonalCard />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <TesimonalCard />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <TesimonalCard />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <TesimonalCard />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <TesimonalCard />
                                </SwiperSlide>
                                {/* Add more slides as needed */}
                            </Swiper>
                            </div>
                            </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default Home