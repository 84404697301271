import React, { useEffect, useRef, useState } from 'react'
import ResourceCard from '../components/ResourceCard';
import { get_board_list, get_grade_list, get_resource, get_subject_list } from '../api/ApiProduct';
import { useSearchParams, useLocation } from 'react-router-dom';
import throttle from 'lodash/throttle';
const Worksheet = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top of the page
    }, [pathname]);
    const breadcrumbStyle = {
        '--bs-breadcrumb-divider': '>'
    };

    const location = useLocation();

    const [getResource, setGetResource] = useState([])
    const [items, setItems] = useState([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const sectionRef = useRef(null);
    const [searchKeyword, setSearchKeyword] = useState(null);

    
    const [boardList, setBoardList] = useState([]);
    const [gradeList, setGradeList] = useState([]);
    const [subjectList, setSubjectList] = useState([]);

    const [boardId, setBoardId] = useState(null);
    const [gradeId, setGradeId] = useState(null);
    const [subjectId, setSubjectId] = useState(null);

    const [dataCount, setDataCount] = useState(0)
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(()=>{
        setSearchKeyword(null);
        setPage(1);
        setGetResource([]);
        setDataCount(0);
    },[location.pathname])


    // 1. Sync state with search parameters on initial load and when URL changes
    useEffect(() => {
        // const board_id = searchParams.get('board');
        // const grade_id = searchParams.get('grade');
        // const subject_id = searchParams.get('subject');

        // if (board_id !== boardId) setBoardId(board_id);
        // if (grade_id !== gradeId) setGradeId(grade_id);
        // if (subject_id !== subjectId) setSubjectId(subject_id);
        setGetResource([]);
        setDataCount(0);
        setPage(1);
    }, [searchParams]);  // Runs when the URL search params change

    // Fetch board list on component mount
    useEffect(() => {
        get_board_list().then((res) => {
            setBoardList(res.data.data);
        }).catch(() => {
            // Handle error
        });
    }, []);

    function handleInput(e){
        setSearchKeyword(e.target.value);
        setGetResource([]);
        setDataCount(0);
    }
    // 2. Handle board selection (from select box)
    function selectBoard(e) {
        const selectedBoardId = e.target.value;
        setBoardId(selectedBoardId);
        setGradeList([]);
        setSubjectList([]);
        setGradeId(null);
        setSubjectId(null);
        setSearchParams({ board: selectedBoardId });
        setGetResource([]);
        setDataCount(0);
        setPage(1);

        get_grade_list({ board_id: selectedBoardId }).then((res) => {
            setGradeList(res.data.data);
        }).catch((err) => {
            // Handle error
        });
    }

    // 3. Handle grade selection (from select box)
    function selectGrade(e) {
        const selectedGradeId = e.target.value;
        setGradeId(selectedGradeId);
        setSubjectList([]);
        setSubjectId(null);
        setGetResource([]);
        setDataCount(0);
        setPage(1);

        // Update the search params in the URL when grade changes
        setSearchParams((prevParams) => ({
            ...Object.fromEntries(prevParams),
            board: boardId,
            grade: selectedGradeId,
        }));

        // Fetch the subject list based on the selected grade
        get_subject_list({ board_id: boardId, grade_id: selectedGradeId }).then((res) => {
            setSubjectList(res.data.data);
        }).catch((err) => {
            // Handle error
        });
    }

    // 4. Handle subject selection (from select box)
    function selectSubject(e) {
        const selectedSubjectId = e.target.value;
        setSubjectId(selectedSubjectId);
        setGetResource([]);
        setDataCount(0);
        setPage(1);
        setSearchParams((prevParams) => ({
            ...Object.fromEntries(prevParams),
            board: boardId,
            grade: gradeId,
            subject: selectedSubjectId,
        }));
    }
    let currentPage = null;
    useEffect(() => {
        let params = { limit: 20, page: page , resource_type: location.pathname == '/worksheet' ? 1 : 2};
        if(searchParams.get('board')) params.board_id = searchParams.get('board')
        if(searchParams.get('grade')) params.grade_id = searchParams.get('grade')
        if(searchParams.get('subject')) params.subject_id = searchParams.get('subject')
        // if (boardId) params.board_id = boardId;
        // if (gradeId) params.grade_id = gradeId;
        // if (subjectId) params.subject_id = subjectId;
        if (searchKeyword) params.search = searchKeyword;

        get_resource(params).then((res) => {
            if(currentPage !== res.data.data.current_page){
                setDataCount((prev)=>prev+res.data.data.values.length);
                currentPage = res.data.data.current_page;
                setGetResource((prevItems) => [...prevItems, ...res.data.data.values]);
                setHasMore(res.data.data.current_page < res.data.data.last_page);
            }
        }).catch(() => {
            // Handle error
        });
    }, [page, searchKeyword, location.pathname, searchParams]);

console.log(location.pathname);
    // function handle_search_submit(event){
    //     event.preventDefault();
    //     setPage(1);
    // }
    // const handleScroll = () => {
    //     if (loading || !hasMore) return;

    //     const section = sectionRef.current;
    //     const threshold = 100; // Trigger threshold in pixels before the end of section
    //     const sectionBottom = section.getBoundingClientRect().bottom;
    //     const viewportHeight = window.innerHeight;

    //     // Check if the section's bottom is within the threshold from the bottom of the viewport
    //     if (sectionBottom - threshold <= viewportHeight) {
    //         setPage((prevPage) => prevPage + 1);
    //     }
    // };
    const handleScroll = throttle(() => {
        if (loading || !hasMore) return; // Prevent fetching when loading or no more items
    
        const section = sectionRef.current;
        const threshold = 100; // Trigger threshold in pixels before the end of section
        const sectionBottom = section.getBoundingClientRect().bottom;
        const viewportHeight = window.innerHeight;
    
        // Check if the section's bottom is within the threshold from the bottom of the viewport
        if (sectionBottom - threshold <= viewportHeight) {
            setPage((prevPage) => prevPage + 1); // Trigger next page loading
        }
    }, 200);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [loading, hasMore]);


    return (
    <>
    <section className="inner-banner p-100">
        <div className="container">
            <div className="row top-row align-items-end">
                <div className="col-md-7 position-relative">
                    <div className="breadcrumbs">
                        <nav style={breadcrumbStyle} aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Worksheet</li>
                            </ol>
                        </nav>
                    </div>
                    <h2 className="site-title text-start">All <span>Worksheet</span></h2>
                    <p>Interactive, research-based Math and Science worksheets fostering <br/>independent learning with real-world applications and digital flexibility.</p>
                </div>
                <div className="col-md-5">
                    <form  className="form-search mt-4 mt-md-0">
                        <div className="input-group">
                            <input className="form-control border-end-0 border" type="search" placeholder="Search your worksheet" id="search" onChange={(e) => handleInput(e)}/>
                            {/* <span className="input-group-append"> */}
                            <span className="">
                                <button className="btn btn-outline-secondary bg-white border-start-0 border-bottom-0 border ms-n5" type="button">
                                    <img src="/assets/images/icon-search.svg" alt="Icon" />
                                </button>
                            </span>
                        </div>
                    </form>
                </div>
            </div>
            <div className="row bot-row">
                <div className="col-md-12">
                    <form action="" className="form-filter">
                        <div className="row">
                            <div className="col-lg-3 col-md-4 mt-3 mt-md-0">
                                <select className="form-control form-select" aria-label="Default select example" onChange={(e)=>selectBoard(e)}>
                                    <option value="" selected>Select Category</option>
                                    {boardList?.map((bl, key)=>{return(
                                    <option value={bl.id}>{bl.board_display_name.toUpperCase()}</option>
                                    )})}
                                    {/* <option value="1">CBSC</option>
                                    <option value="2">PRE-PRIMARY</option>
                                    <option value="3">MAHARASHTRA STATE BOARD</option> */}
                                </select>
                            </div>
                            <div className="col-lg-3 col-md-4 mt-3 mt-md-0">
                                <select className="form-control form-select" aria-label="Default select example" onChange={(e)=>selectGrade(e)}>
                                    <option value="" selected>Select Class</option>
                                    {gradeList?.map((gl, key)=>{return(
                                    <option value={gl.id}>{gl.grade_display_name.toUpperCase()}</option>
                                    )})}
                                    {/* <option value="2">12 Commerce</option>
                                    <option value="3">12 Commerce</option> */}
                                </select>
                            </div>
                            <div className="col-lg-3 col-md-4 mt-3 mt-md-0">
                                <select className="form-control form-select" aria-label="Default select example" onChange={(e)=>selectSubject(e)}>
                                    <option value="" selected>Select Subject</option>
                                    {subjectList?.map((sl, key)=>{return(
                                    <option value={sl.id}>{sl.subject_name.toUpperCase()}</option>
                                    )})}
                                    {/* <option value="2">Maths</option>
                                    <option value="3">Biology</option> */}
                                </select>
                            </div>
                            {/* <div className="col-lg-3 text-center text-lg-start mt-3 mt-lg-0">
                                <button type="submit" className="site-btn border-0">Apply</button>
                            </div> */}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
    {/* <!-- Inner Banner Ends Here --> */}

    {/* <!-- Work Sheet Lists Starts Here --> */}
    <section className="worksheet-lists p-100" ref={sectionRef}>
        <div className="container">
            
            <div className="row">
                {getResource?.map((gr, key)=>{  return (
                <ResourceCard key={key} data={gr}/>
                )})}
                {dataCount == 0 && <div>No data found</div>}
            </div>
            
        {loading && <div>loading...</div>}
        </div>
    </section>
    {/* <!-- Work Sheet Lists Ends Here --> */}
</>
  )
}

export default Worksheet