import React from 'react'
import { thumbnail } from '../api/Common';
import { Link } from 'react-router-dom';

const DownloadCard = ({data}) => {
  return (
        <div class="feature-box bg-white">
            {data && 
            <div class="imgwrp">
                <img src={thumbnail()+data.thumbnail} alt="Image" />
                {data.resource_title.toUpperCase()}
            </div>
            }
            {/* {data && data.is_paid == 0 &&
            <div class="col-auto text-end">
                <img src="/assets/images/free.png" alt="Image" />
            </div>} */}
            <div class="cntwrp">
                {/* <p>{data.board_name} {data.grade_name} {data.subject_name} {data.chapter_name}<br /> {data.resource_title}</p> */}
                <p>{data.grade_name.toUpperCase()}<br /> {data.resource_title.toUpperCase()}</p>
                <Link to={`/view/${data.id}/${data.resource_title.replace(/\s+/g, '-')}`} 
                class="site-secondary-btn"><span>Download</span></Link>

            </div>
        </div>
  )
}

export default DownloadCard