import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import ModalLogin from './ModalLogin';
import ModalRegister from './ModalRegister';
import { get_menu_category, get_profile } from '../api/ApiAuth';
import { useNavigate } from 'react-router-dom';
import ModalForgotPassword from './ModalForgotPassword';
const Header = ({profile, setProfile}) => {
    // const redirect = useNavigate();

    // useEffect(()=>{
    //     if(!profile){
    //         redirect('/');
    //     }
    // },[profile])
 console.log(profile)
    const [menuCategory, setMenuCategory] = useState([])
    useEffect(() => {
        get_menu_category().then((res)=>{
            setMenuCategory(res.data.data);
        }).catch((err)=>{
            
        })
    }, [])
        
  return (
    <header>
        <div class="container">
            <div class="row align-items-center">
                <div class="col-auto left-sec logo-wrp">
                    <Link to="/" class="logo">
                        <img src="/assets/images/logo.svg" alt="Logo" />
                    </Link>
                    <div class="menu-toggle">
                        <div class="patty"></div>
                    </div>
                </div>
                <div class="col d-flex justify-content-end align-items-center right-sec mob-menu">
                    <nav>
                        <ul class="main-menu d-flex justify-content-center gap-4">
                            <li><Link to="/membership">Membership</Link></li>
                            {/* <li>
                                <div class="dropdown">
                                    <a href="#" class="btn p-0 dropdown-toggle work-sheet-menu" type="button" data-bs-toggle="dropdown" aria-expanded="false">Work Sheet</a>
                                    <button class="p-0 dropdown-toggle-one" type="button" data-bs-toggle="dropdown" aria-expanded="false"></button>
                                    <ul class="dropdown-menu">
                                        <li><a class="dropdown-item" href="#">Action</a></li>
                                        <li><a class="dropdown-item" href="#">Another action</a></li>
                                        <li><a class="dropdown-item" href="#">Something else here</a></li>
                                    </ul>
                                </div>
                            </li> */}
                            {/* <li>
                                <div class="dropdown">
                                    <Link to="/worksheet" class="btn p-0 dropdown-toggle work-sheet-menu" type="button" data-bs-toggle="dropdown" aria-expanded="false">Worksheet</Link>
                                    <button class="p-0 dropdown-toggle-one" type="button" data-bs-toggle="dropdown" aria-expanded="false"></button>
                                    <ul class="dropdown-menu">
                                        {menuCategory?.map((mc, key)=>{
                                            return(
                                        <li class="dropdown dropend">
                                            <Link to="/worksheet" class="dropdown-item" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{mc.name}</Link>
                                            <button class="p-0 dropdown-toggle-one" type="button" data-bs-toggle="dropdown" aria-expanded="false"></button>
                                            <ul class="dropdown-menu" aria-labelledby={`multilevelDropdownMenu2${key}`}>
                                                {mc.grades.map((mcg)=>{return(
                                                <li class="dropdown dropend">
                                                    <a href="#" class="dropdown-item" type="button" data-bs-toggle="dropdown" aria-expanded="false">{mcg.name}</a>
                                                    <button class="p-0 dropdown-toggle-one" type="button" data-bs-toggle="dropdown" aria-expanded="false"></button>
                                                    <ul class="dropdown-menu">
                                                        {mcg.subjects.map((mcgs)=>{return(
                                                        <li><a class="dropdown-item" href="#">{mcgs.subject_name}</a></li>)})}
                                                    </ul>
                                                </li>
                                                )})}
                                            </ul>
                                        </li>
                                        )})}
                                        <li><a class="dropdown-item" href="#">Something else here</a></li>
                                    </ul>
                                </div>
                            </li> */}
                            <li>
                                <div className="dropdown">
                                    <Link
                                        to="/worksheet"
                                        className="btn p-0 dropdown-toggle work-sheet-menu"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        Worksheet
                                    </Link>
                                    <button
                                        className="p-0 dropdown-toggle-one"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    ></button>
                                    <ul className="dropdown-menu">
                                        <li><Link to="/worksheet" class="dropdown-item" >Worksheet</Link></li>
                                        {menuCategory?.map((mc, key) => (
                                            <li key={key} className="dropdown dropend">
                                                <Link
                                                    to={`/worksheet?board=${mc.id}`}
                                                    className="dropdown-item"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                >
                                                    {mc.name}
                                                </Link>
                                                {mc.grades && mc.grades.length > 0 && (
                                                    <>
                                                        <button
                                                            className="p-0 dropdown-toggle-one"
                                                            type="button"
                                                            data-bs-toggle="dropdown"
                                                            aria-expanded="false"
                                                        ></button>
                                                        <ul className="dropdown-menu" aria-labelledby={`multilevelDropdownMenu2${key}`}>
                                                            {mc.grades.map((mcg) => (
                                                                <li key={mcg.id} className="dropdown dropend">
                                                                    <Link
                                                                        to={`/worksheet?board=${mc.id}&grade=${mcg.id}`}
                                                                        className="dropdown-item"
                                                                        type="button"
                                                                        aria-expanded="false"
                                                                    >
                                                                        {mcg.name}
                                                                    </Link>
                                                                    {mcg.subjects && mcg.subjects.length > 0 && (
                                                                        <>
                                                                            <button
                                                                                className="p-0 dropdown-toggle-one"
                                                                                type="button"
                                                                                data-bs-toggle="dropdown"
                                                                                aria-expanded="false"
                                                                            ></button>
                                                                            <ul className="dropdown-menu">
                                                                                {mcg.subjects.map((mcgs) => (
                                                                                    <li key={mcgs.id}>
                                                                                        <Link
                                                                                            to={`/worksheet?board=${mc.id}&grade=${mcg.id}&subject=${mcgs.id}`}
                                                                                            className="dropdown-item"
                                                                                        >
                                                                                            {mcgs.subject_name}
                                                                                        </Link>
                                                                                    </li>
                                                                                ))}
                                                                            </ul>
                                                                        </>
                                                                    )}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </>
                                                )}
                                            </li>
                                        ))}
                                        {/* <li><a className="dropdown-item" href="#">Something else here</a></li> */}
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div className="dropdown">
                                    <Link
                                        to="/notes"
                                        className="btn p-0 dropdown-toggle work-sheet-menu"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        Notes
                                    </Link>
                                    <button
                                        className="p-0 dropdown-toggle-one"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    ></button>
                                    <ul className="dropdown-menu">
                                        <li><Link to="/notes" class="dropdown-item" >Notes</Link></li>
                                        {menuCategory?.map((mc, key) => (
                                            <li key={key} className="dropdown dropend">
                                                <Link
                                                    to={`/notes?board=${mc.id}`}
                                                    className="dropdown-item"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                >
                                                    {mc.name}
                                                </Link>
                                                {mc.grades && mc.grades.length > 0 && (
                                                    <>
                                                        <button
                                                            className="p-0 dropdown-toggle-one"
                                                            type="button"
                                                            data-bs-toggle="dropdown"
                                                            aria-expanded="false"
                                                        ></button>
                                                        <ul className="dropdown-menu" aria-labelledby={`multilevelDropdownMenu2${key}`}>
                                                            {mc.grades.map((mcg) => (
                                                                <li key={mcg.id} className="dropdown dropend">
                                                                    <Link
                                                                        to={`/notes?board=${mc.id}&grade=${mcg.id}`}
                                                                        className="dropdown-item"
                                                                        type="button"
                                                                        aria-expanded="false"
                                                                    >
                                                                        {mcg.name}
                                                                    </Link>
                                                                    {mcg.subjects && mcg.subjects.length > 0 && (
                                                                        <>
                                                                            <button
                                                                                className="p-0 dropdown-toggle-one"
                                                                                type="button"
                                                                                data-bs-toggle="dropdown"
                                                                                aria-expanded="false"
                                                                            ></button>
                                                                            <ul className="dropdown-menu">
                                                                                {mcg.subjects.map((mcgs) => (
                                                                                    <li key={mcgs.id}>
                                                                                        <Link
                                                                                            to={`/notes?board=${mc.id}&grade=${mcg.id}&subject=${mcgs.id}`}
                                                                                            className="dropdown-item"
                                                                                        >
                                                                                            {mcgs.subject_name}
                                                                                        </Link>
                                                                                    </li>
                                                                                ))}
                                                                            </ul>
                                                                        </>
                                                                    )}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </>
                                                )}
                                            </li>
                                        ))}
                                        {/* <li><a className="dropdown-item" href="#">Something else here</a></li> */}
                                    </ul>
                                </div>
                            </li>
                            {/* <li>
                                <div class="dropdown">
                                    <a href="#" class="btn p-0 dropdown-toggle work-sheet-menu" type="button" data-bs-toggle="dropdown" aria-expanded="false">Mock Test Series</a>
                                    <button class="p-0 dropdown-toggle-one" type="button" data-bs-toggle="dropdown" aria-expanded="false"></button>
                                    <ul class="dropdown-menu">
                                        <li><a class="dropdown-item" href="#">Action</a></li>
                                        <li><a class="dropdown-item" href="#">Another action</a></li>
                                        <li><a class="dropdown-item" href="#">Something else here</a></li>
                                    </ul>
                                </div>
                            </li> */}
                        </ul>
                    </nav>
                    {/* <div class="auth-buttons">
                        <ul class="btn-group gap-3">
                            <li><a href="javascript:void(0)" class="site-btn white-btn"><span>Join Now</span></a></li>
                            <li><a href="javascript:void(0)" class="site-btn">Login</a></li>
                        </ul>
                    </div> */}
                    <div class="auth-buttons">
                        {!profile ? 
                        <ul class="btn-group gap-3">
                            <li><a href="javascript:void(0)" class="site-btn white-btn" data-bs-toggle="modal" data-bs-target="#registerModal"><span>Join Now</span></a></li>
                            <li><a href="javascript:void(0)" class="site-btn" data-bs-toggle="modal" data-bs-target="#loginModal" id='loginModalbtn'>Login</a></li>
                        </ul>
                        :<ul class="btn-group gap-3">
                        <li> <Link to="/dashboard"  class="" >
                            <img src="/assets/images/profile-icon.png" alt="Profile Icon" style={{width: "40px", height: "40px"}} />
                        </Link></li>
                        
                    </ul>}

                    </div>
                </div>
            </div>
        </div>
        
        <ModalLogin setProfile={setProfile}/>
        <ModalRegister setProfile={setProfile}/>
        <ModalForgotPassword />
        
    </header>
  )
}

export default Header