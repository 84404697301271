export function thumbnail(){
    // return "http://127.0.0.1:8000/storage/uploads/thumbnail/";
    return "https://bwitknowlearn.glowspotup.com/storage/uploads/thumbnail/";
}
export function configuration(){
    return  {
        // headers: { 'Accept': 'application/json', "token":"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2J3aXRrbm93bGVhcm4uZ2xvd3Nwb3R1cC5jb20vYXBpL2xvZ2luIiwiaWF0IjoxNzI1NzczNTAwLCJleHAiOjE3MjY2Mzc1MDAsIm5iZiI6MTcyNTc3MzUwMCwianRpIjoiekNoWTgwM0M2U0F2NHhGaiIsInN1YiI6IjEwMzg1OSIsInBydiI6ImMzN2UyOTQ1ZWFhNDU3MGIzZWEyNzIwY2YwMjhkMGExOGJhZjk4MjMifQ.dLkg7iq-2qZJWFToF2fLM0qvcNl87lfl5vGFmbAQ3G8"}
        headers: { 'Accept': 'application/json'}
     };
     return  {
        headers: { 'Accept': 'application/json'}
     };
}