import React from 'react'

const Footer = () => {
  return (
    <footer>
        <div class="top-footer">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <a href="index.php">
                            <img src="/assets/images/logo-footer.svg" alt="Logo" />
                        </a>
                    </div>
                    <div class="col">
                        <h4>For Students</h4>
                        <ul>
                            <li><a href="javascript:void(0)">Worksheet</a></li>
                            <li><a href="javascript:void(0)">Practice question paper</a></li>
                            <li><a href="javascript:void(0)">Mock test series</a></li>
                        </ul>
                    </div>
                    <div class="col">
                        <h4>For Parents</h4>
                        <ul>
                            <li><a href="javascript:void(0)">Learning for child</a></li>
                            <li><a href="javascript:void(0)">Worksheet for child</a></li>
                            <li><a href="javascript:void(0)">Mock sheet for child</a></li>
                        </ul>
                    </div>
                    <div class="col">
                        <h4>For Parents</h4>
                        <ul>
                            <li><a href="javascript:void(0)">Learning for child</a></li>
                            <li><a href="javascript:void(0)">Worksheet for child</a></li>
                            <li><a href="javascript:void(0)">Mock sheet for child</a></li>
                        </ul>
                    </div>
                    <div class="col-auto">
                        <h4>Get Contact</h4>
                        <ul class="contact-details">
                            <li><strong>Email:</strong> <a href="mailto:contact@witknowlearn.com">contact@witknowlearn.com</a></li>
                            <li><strong>Phone No:</strong> <a href="tel:+917900069140">+91-79000 69140</a></li>
                        </ul>
                        <ul class="social-group d-flex gap-3">
                            <li><a href="javascript:void(0)"><img src="/assets/images/icon-insta.svg" alt="Icon" /></a></li>
                            <li><a href="javascript:void(0)"><img src="/assets/images/icon-fb.svg" alt="Icon" /></a></li>
                            <li><a href="javascript:void(0)"><img src="/assets/images/icon-twitter.svg" alt="Icon" /></a></li>
                            <li><a href="javascript:void(0)"><img src="/assets/images/icon-youtube.svg" alt="Icon" /></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="bottom-footer text-white">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 left">
                        <p class="mb-0">2024. All rights reserved by witknowlearn.</p>
                    </div>
                    <div class="col-md-6 right">
                        <ul class="d-flex justify-content-end gap-4">
                            <li><a href="javascript:void(0)">Term of service</a></li>
                            <li><a href="javascript:void(0)">Privacy Policy</a></li>
                            <li><a href="javascript:void(0)">Subscription</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </footer>
  )
}

export default Footer