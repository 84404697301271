import React, { useState } from 'react'
import { register } from '../api/ApiAuth'
import { GoogleLogin } from '@react-oauth/google';
import axios from 'axios';

const ModalRegister = ({setProfile}) => {
    const [errors, setErrors] = useState({});
    const [alertError, setAlertError] = useState(null)
    const [formData, setFormData] = useState({
        signup_fullname: '',
        signup_email: '',
        signup_mobile: '',
        signup_password: '',
    });
    function form_data(e){
        setFormData(prev=>({...prev, [e.target.name]:e.target.value}))
    }
    function submit_register(){
        const validationErrors = validateForm();
        console.log(Object.keys(validationErrors));
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        setErrors({})
        register(formData).then((res)=>{
            setProfile(res.data.user);
            var closeButton = document.querySelector('.closeModalButton2');
            closeButton.click();
        }).catch((err)=>{
            if (err.response && err.response.status === 422) {
                const apiErrors = err.response.data.errors;
                setErrors(apiErrors);  // Set server-side validation errors
            }
            else{
                setAlertError('something went wrong');
            }
        })
    }
    function validateForm() {
        setAlertError(null);
        const newErrors = {};
        // Full name validation
        if (!formData.signup_fullname.trim()) {
            newErrors.signup_fullname = 'Full Name is required';
        }

        // Email validation
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!formData.signup_email) {
            newErrors.signup_email = 'Email is required';
        } else if (!emailPattern.test(formData.signup_email)) {
            newErrors.signup_email = 'Please enter a valid email';
        }

        // Mobile validation (you can customize based on the format you expect)
        if (!formData.signup_mobile) {
            newErrors.signup_mobile = 'Mobile number is required';
        } else if (formData.signup_mobile.length < 10) {
            newErrors.signup_mobile = 'Mobile number must be at least 10 digits';
        }

        // Password validation
        if (!formData.signup_password) {
            newErrors.signup_password = 'Password is required';
        } else if (formData.signup_password.length < 6) {
            newErrors.signup_password = 'Password must be at least 6 characters';
        }

        // Category validation
        // if (!formData.signup_type) {
        //     newErrors.signup_type = 'Please select a category';
        // }

        return newErrors;
    }
    const handleLoginSuccess = async (response) => {
        const { credential } = response;
        try {
          const { data } = await axios.post('google-login', {
            token: credential,
          });
    
          // You can now handle the user data and token returned from your backend
          setProfile(data.user);
            var closeButton = document.querySelector('.closeModalButton');
            closeButton.click();
          console.log('User data from backend:', data);
        } catch (error) {
          console.error('Google login error:', error);
        }
      };
    
      const handleLoginFailure = () => {
        console.log('Login Failed');
      };
  return (
    <div class="modal fade site-modal register-modal" id="registerModal" tabindex="-1" aria-labelledby="registerModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content rounded-4">
                    <div class="modal-body d-flex flex-column align-items-center px-4 py-4 mx-auto">
                        <div class="row w-100 gx-5">
                            <div class="col-lg-12 align-self-center right p-0">
                                <h2 class="site-title"><span>Join with us</span></h2>
                                <button type="button" class="btn-close closeModalButton2 d-none" data-bs-dismiss="modal" aria-label="Close" ></button>
                                {alertError && (
                                <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                    {alertError}
                                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                </div>
                                )} 
                                {/* <form action="" method="post"> */}
                                <div class="form-group mb-4">
                                    <input type="text" class="form-control" id="regFullName" name="signup_fullname" placeholder="Full Name" required onChange={(e)=>form_data(e)}/>
                                    {errors.signup_fullname && <span className="text-danger">{errors.signup_fullname}</span>}
                                </div>
                                <div class="form-group mb-4">
                                    <input type="email" class="form-control" id="regMail" name="signup_email" placeholder="Email" required onChange={(e)=>form_data(e)}/>
                                    {errors.signup_email && <span className="text-danger">{errors.signup_email}</span>}
                                </div>
                                <div class="form-group mb-4">
                                    <input type="text" class="form-control" id="regMobile" name="signup_mobile" placeholder="Mobile" required onChange={(e)=>form_data(e)}/>
                                    {errors.signup_mobile && <span className="text-danger">{errors.signup_mobile}</span>}
                                </div>
                                <div class="form-group mb-4 position-relative">
                                    <input type="password" class="form-control" id="regPassword" name="signup_password" placeholder="Password" required onChange={(e)=>form_data(e)}/>
                                    {errors.signup_password && <span className="text-danger">{errors.signup_password}</span>}
                                </div>
                                {/* <div class="form-group mb-4">
                                    <select class="form-control form-select" id="regCategory" name="signup_type" required onChange={(e)=>form_data(e)}>
                                        <option value="" disabled selected>Choose your category</option>
                                        <option value="1">parents</option>
                                        <option value="2">teacher</option>
                                        <option value="3">student</option>
                                    </select>
                                    {errors.signup_type && <span className="text-danger">{errors.signup_type}</span>}
                                </div> */}
                                <button type="submit" class="btn site-btn mx-auto d-block mt-4" onClick={submit_register}>Submit</button>
                                <div class="goggle-signin text-center">
                                    <p class="mb-1">or continue with 
                                    <GoogleLogin
                                            onSuccess={handleLoginSuccess}
                                            onError={handleLoginFailure}
                                        />
                                        {/* <a href="javascript:void(0)" class="google-btn d-table"><img src="/assets/images/icon-google.svg" alt="Icon" /></a> */}
                                    </p>
                                </div>
                                <div class="new-signup text-center">
                                    <p class="mb-1">You have an account? <a href="javascript:void(0)" class="reg-btn fw-bold" data-bs-target="#loginModal" data-bs-dismiss="modal" data-bs-toggle="modal">Login here</a></p>
                                </div>
                                    
                                {/* </form> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default ModalRegister