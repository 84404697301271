import React from 'react'
import { Link } from 'react-router-dom'
import { thumbnail } from '../api/Common'

const ResourceCard = ({data}) => {
  return (
    // <Link to={`/view/${data.id}/${data.resource_title}`}></Link>
// {/* <Link to={`/view/${data.id}/${data.resource_title.replace("/\s+/", '-')}`} className="col-lg-3 col-md-4"> */}
<Link to={`/view/${data.id}/${data.resource_title.replace(/\s+/g, '-')}`} className="col-lg-3 col-md-4">

                    <div className="card single-sheet">
                        <div className="card-head text-center">
                            <img src={thumbnail()+data.thumbnail} alt="Image" className="img-fluid"/>
                        </div>
                        <div className="card-body p-0 pt-3">
                            <div className="title-wrp pt-1 d-flex row gx-2">
                                <div className="col">
                                    <h4>{data.grade_name.toUpperCase()}</h4>
                                    {/* <div className="ratings d-flex">
                                        <div className="img-groups d-flex gap-1">
                                            <img src="/assets/images/icon-star.svg" alt="Icon" />
                                            <img src="/assets/images/icon-star.svg" alt="Icon" />
                                            <img src="/assets/images/icon-star.svg" alt="Icon" />
                                            <img src="/assets/images/icon-star.svg" alt="Icon" />
                                            <img src="/assets/images/icon-star.svg" alt="Icon" />
                                        </div>
                                        <p className="mb-0 ms-2">(1 Reviews)</p>
                                    </div> */}
                                </div>
                                {/* {data.is_paid == 0 &&
                                <div class="col-auto text-end">
                                    <img src="/assets/images/free.png" alt="Image" />
                                </div>} */}
                            </div>
                            <h3>{data.resource_title.toUpperCase()} </h3>
                            {/* <p>{data.board_name}</p> */}
                            {/* <Link to={`/view/${data.id}/${data.resource_title}`}><div className="btn-wrp"><a className="site-secondary-btn"><span>More Details</span></a></div></Link> */}
                        </div>
                    </div>
                </Link>
  )
}

export default ResourceCard